import React from 'react';
import Helmet from 'react-helmet';

import Copy from '@nib-components/copy';
import Heading from '@nib-components/heading';
import { Box, Container, Section, Stack } from '@nib/layout';

import Layout from '../../components/Layout';
import { ContentfulDocument } from '../../constructs/models';
import metrics from '../../metrics';
import LifeLivingInsuranceSection, {
  LifeLivingInsuranceSectionProps,
} from '../../page-sections/life-living-insurance-section';
import { NavContentProps, NavLinkProps } from '../../components/NavContent';
import WomanOnLaptopImage from '../../img/life-and-living/woman-on-laptop.jpeg';
import { ImportantInformationBannerProps } from '../../components/ImportantInformationBanner';
import { ExternalLinkSystemIcon } from '@nib/icons';
import Link from '@nib-components/link';
import { ContentHeroPanelProps } from '../../components/ContentHeroPanel';
import Form from '@nib-components/form';
import { FormPanel } from '@nib/content-services-library/dist/cjs/components/form-panel';
import useSalesforceForm from '../../hooks/useSalesforceForm';
import { urlConstants } from '../../constructs/constants';

const title = 'Life Insurance and Living Insurance Contact';
const description =
  'New Zealanders can get peace of mind if they pass away or can’t work anymore with life insurance or illness and income protection cover from nib.';

const contentHeroData: ContentHeroPanelProps = {
  title: (
    <>
      Let’s talk about <span style={{ color: '#82E578' }}>Life & Living insurance.</span>
    </>
  ),
  subtextLeft: 'LIFE INSURANCE',
  subtextRight: 'LIVING INSURANCE',
  description: <>It’s healthy to talk about death. It’s even better to plan for it.</>,
  imageMobile: WomanOnLaptopImage,
  imageDesktop: WomanOnLaptopImage,
  imageAlt:
    'A woman smiles while seated at a desk, working on her laptop, exuding a sense of positivity and focus.',
  type: 'banner',
};

const navigationLinks: NavLinkProps[] = [
  {
    text: 'About Life & Living insurance',
    isActive: false,
    href: urlConstants.aboutLifeLivingInsurance,
  },
  {
    text: 'Special offers',
    isActive: false,
    href: urlConstants.currentOfferLifeLivingInsurance,
  },
  {
    text: 'Why get Life & Living insurance',
    isActive: false,
    href: urlConstants.whyLifeLivingInsuranceCoverIsImportant,
  },
  {
    text: 'Estimator Tool',
    isActive: false,
    href: urlConstants.estimateYourCoverageLifeLivingInsurance,
  },
  {
    text: 'For homeowners',
    isActive: false,
    href: urlConstants.lifeLivingHomeowners,
  },
  { text: 'How to apply', isActive: true, href: urlConstants.howToApplyLifeLivingInsurance },
];

const navigation: NavContentProps = {
  title: 'Life & Living insurance',
  homeHref: urlConstants.compareLifeAndLivingInsurance,
  primaryButton: { text: 'Apply now', href: urlConstants.estimateYourCoverageLifeLivingInsurance },
  secondaryButton: { text: 'Contact us', href: urlConstants.lifeLivingInsuranceContactUs },
  navigation: navigationLinks,
};

const importantInformationData: ImportantInformationBannerProps = {
  title: 'Important Information',
  description: [
    <Copy measure={false} size="small">
      Only the main features and benefits of the Life & Living Insurance is outlined here. For a
      full explanation of all benefits, exclusions and other terms and conditions of the cover, view
      the{' '}
      <Link href={urlConstants.lifeAndLivingInsuranceWording} icon={ExternalLinkSystemIcon}>
        Life & Living Insurance wording
      </Link>
      .
    </Copy>,
    <Copy measure={false} size="small">
      Life & Living Insurance is provided by nib nz insurance limited and is a related company of
      nib nz limited (“nib”). nib nz insurance limited issues and underwrites Life & Living
      Insurance products, and is solely responsible for claims under the cover. nib sells and may
      advise you on these products. Find out more about{' '}
      <Link href={urlConstants.financialStrength}>
        financial strength and financial advice with nib
      </Link>
      .
    </Copy>,
  ],
};

const lifeLivingSectionData: LifeLivingInsuranceSectionProps = {
  heroContent: contentHeroData,
  navigation: navigation,
  importantInformationData: importantInformationData,
};

type DocumentResult = {
  edges: {
    node: {
      document: ContentfulDocument;
    };
  }[];
};

interface ImageShape {
  file: {
    url: string;
  };
}

interface HtmlMarkdownShape {
  childMarkdownRemark: {
    html: string;
  };
}
interface RawAndHtmlMarkdownShape {
  childMarkdownRemark: {
    html: string;
    rawMarkdownBody: string;
  };
}

interface HeroImagesShape {
  desktop: ImageShape;
  desktop2x4000x1200: ImageShape;
  mobile2x800x300: ImageShape;
  mobile400x150: ImageShape;
  tablet2x1600x580: ImageShape;
  tablet800x290: ImageShape;
}

interface NodeShape {
  title: string;
  startDate: string;
  heroTitle: string;
  heroCopy: RawAndHtmlMarkdownShape;
  leftButtonText: string;
  rightButtonText: string;
  legal: RawAndHtmlMarkdownShape;
  campaignCode: string;
  heroImage: HeroImagesShape;
  importantThingsToKnow: HtmlMarkdownShape;
}

type LifeCoverOptionsPageResult = {
  edges: {
    node: NodeShape;
  }[];
};

type GraphQLResult = {
  temporaryAccidentalDeathCover: DocumentResult;
  lifePolicyDocument: DocumentResult;
  factSheetUrl: DocumentResult;
  lifeCoverOptionsPage: LifeCoverOptionsPageResult;
};

interface LifeCoverOptionsProps {
  data: GraphQLResult;
}

const LifeCoverOptions = ({ data }: LifeCoverOptionsProps): JSX.Element => {
  const { callbackFormSrc, flowName } = useSalesforceForm();
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    '@id': 'WebPage',
    identifier: 'https://www.nib.co.nz/life-cover-options/contact-us',
    url: 'https://www.nib.co.nz/life-cover-options/contact-us',
    description:
      'New Zealanders can get peace of mind if they pass away or can’t work anymore with life insurance or illness and income protection cover from nib.',
    name: ['Life Insurance and Living Insurance | nib'],
    isPartOf: 'https://www.nib.co.nz',
  };
  const JSONschema = JSON.stringify(schema);

  return (
    <Layout>
      <Helmet>
        <title>{title} | nib</title>
        <meta name="description" content={description} />
        <script type="application/ld+json">{JSONschema}</script>
      </Helmet>
      <LifeLivingInsuranceSection {...lifeLivingSectionData}>
        <Section>
          <Box padding="5">
            <Stack space={5}>
              <Stack space={3}>
                <Heading size={{ xs: 2 }} color="trueGreen" component="h2">
                  We'll get in touch
                </Heading>
                <Copy measure={false}>
                  If you need help deciding what life and living cover is right for you, request a
                  call from us below, and one of our friendly consultants will call you back,
                  normally within one working day. Alternatively, give us a call, or send us an
                  email.
                </Copy>
              </Stack>
              <ArrangeACallBackSection src={callbackFormSrc} flowName={flowName} />
              <Box display="flex" flexDirection="column" gap="5">
                <Box>
                  <Heading size={{ xs: 4 }} color="trueGreen" component="h3">
                    Hours
                  </Heading>
                  <Copy>Monday-Friday</Copy>
                  <Copy>9:00am to 5:00pm</Copy>
                </Box>
                <Box>
                  <Heading size={{ xs: 4 }} color="trueGreen" component="h3">
                    Phone
                  </Heading>
                  <Copy>
                    <Link href="tel:0800555642">0800 555 642</Link>
                  </Copy>
                  <Copy>Press option 1</Copy>
                </Box>
                <Box>
                  <Heading size={{ xs: 4 }} color="trueGreen" component="h3">
                    Email
                  </Heading>
                  <Copy>
                    <Link href="mailto:lifesales@nib.co.nz">lifesales@nib.co.nz</Link>
                  </Copy>
                </Box>
              </Box>
            </Stack>
          </Box>
        </Section>
      </LifeLivingInsuranceSection>
    </Layout>
  );
};

const ArrangeACallBackSection = ({
  src,
  flowName,
}: {
  src: string;
  flowName: string | null;
}): JSX.Element => {
  // The script seems to only work for certain whitelsited(?) hostnames
  return (
    <Box role="section" id="book-a-call">
      <Box paddingHorizontal={{ xs: 4, md: 6, lg: 8 }}>
        <Container>
          <div style={{ display: 'none' }}>{flowName}</div>
          {flowName && (
            <Form id="programmeEnquiryForm" name="programmeEnquiryForm" formMode="white">
              <FormPanel
                variant="salesforceFlow"
                scriptSrc={src}
                appName="c:flowApp"
                componentName="lightning:flow"
                domLocator="flowContainer"
                flowName={flowName}
                attributes={{ class: 'myCustomClass' }}
              />
            </Form>
          )}
        </Container>
      </Box>
    </Box>
  );
};

export default metrics({ pageName: 'life-and-living-contact' })(LifeCoverOptions);
